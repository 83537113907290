interface Countdown {
  /**
   * Set the duration of the countdown
   *
   * @default 0
   */
  duration: number
  /**
   * Start the countdown immediately after the component is mounted
   *
   * @default false
   */
  immediate?: boolean
  /**
   * Callback when the countdown starts
   */
  onStart?: () => void
  /**
   * Callback when the countdown ends
   */
  onEnd?: () => void
}

export function useCountdown({
  duration,
  immediate,
  onStart,
  onEnd,
  // you can add more options here like `onPause`, `onResume`, `onStop` etc.
}: Countdown,
) {
  const countdown = ref(0)
  const interval = ref(0)

  function start() {
    onStart?.()
    countdown.value = duration
    interval.value = setInterval(() => {
      countdown.value--
    }, 1000) as unknown as number
  }

  function stop() {
    countdown.value = -1 // set to -1 to prevent the onEnd callback from being called
    clearInterval(interval.value)
  }

  watch(countdown, (value) => {
    if (value === 0) {
      // stop()
      clearInterval(interval.value)
      onEnd?.()
    }
  })

  onMounted(() => {
    if (immediate)
      start()
  })

  return {
    countdown, // the current countdown value
    isStarted: computed(() => countdown.value < duration), // if the countdown is started
    isFinished: computed(() => countdown.value === 0), // if the countdown is finished
    isStopped: computed(() => countdown.value < 0), // if the countdown is stopped (-1)

    start, // start the countdown
    stop, // stop the countdown
  }
}
