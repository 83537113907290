export function useMediaSettings({
  enableCamera = true,
  enableMicrophone = true,
}: {
  enableCamera?: boolean
  enableMicrophone?: boolean
} | undefined = {}) {
  const {
    videoInputs: cameras,
    audioInputs: microphones,
  } = useDevicesList({
    requestPermissions: true,
  })

  const currentCamera = useLocalStorage('current-camera', () => cameras.value[0])
  const currentMicrophone = useLocalStorage('current-microphone', () => microphones.value[0])

  const userMedia = useUserMedia({
    constraints: {
      video: enableCamera ? { deviceId: currentCamera } as MediaTrackConstraints : false,
      audio: enableMicrophone ? { deviceId: currentMicrophone, echoCancellation: false } as MediaTrackConstraints : false,
    },
    autoSwitch: true,
  }) as ReturnType<typeof useUserMedia>

  // Initial fix for camera reactivity
  watch(currentCamera, () => {
    userMedia.enabled.value = false

    nextTick(() => {
      userMedia.enabled.value = true
    })
  })

  return {
    currentCamera,
    currentMicrophone,

    cameras,
    microphones,

    ...userMedia,
  }
}
