import { useRouteParams } from '@vueuse/router'
import type { Interview, InterviewAnswer, Question } from '~/types'

/**
 * @file This store is responsible for managing the room or interview related states and actions.
 *
 * @todo
 * - [x] Add a way to start the interview
 * - [ ] Add next question button
 * - [ ] Add a way to submit/finish the interview
 * - [ ] Add a way to cancel the interview
 */

export const useRoomStore = defineStore('room', () => {
  // STATES
  const room = ref<Interview>()
  const pending = ref(false)
  const token = ref(useRouteParams<string>('token'))

  // interview questions related
  const questions = computed<Question[]>(() => room.value?.jobPost?.questions ?? [])
  const answers = computed<InterviewAnswer[]>(() => room.value?.interviewAnswers ?? [])
  const answeredQuestions = computed<Question[]>(() => questions.value.filter(question => answers.value.find(answer => answer.questionId === question.questionId)))
  const unansweredQuestions = computed<Question[]>(() => questions.value.filter(question => !answers.value.find(answer => answer.questionId === question.questionId)))
  const currentQuestion = computed<Question>(() => unansweredQuestions.value[0])

  // ACTIONS
  async function getRoom() {
    if (!token.value)
      return

    const response = await useFetch(`/api/public/interviews/${token.value}`, {
      transform: (data: { data: Interview }) => useCase().toCamelCase(data.data),
      onRequest: () => pending.value = true,
    })

    room.value = response.data.value
    pending.value = false

    return response
  }

  async function submitAnswer(answer: InterviewAnswer) {
    if (!token.value)
      return

    const response = await useFetch(`/api/public/interview_room/${token.value}/interview_answers`, {
      method: 'POST',
      params: answer,
      transform: (data: { data: InterviewAnswer }) => useCase().toCamelCase(data.data),
      onRequest: () => pending.value = true,
    })

    room.value = response.data.value
    pending.value = false

    return response
  }

  async function updateRoom(params?: Interview) {
    if (!token.value)
      return

    const response = await useFetch(`/api/public/interviews/${token.value}`, {
      method: 'PATCH',
      params,
      transform: (data: { data: Interview }) => useCase().toCamelCase(data.data),
      onRequest: () => pending.value = true,
    })

    room.value = response.data.value
    pending.value = false

    return response
  }

  return {
    state: computed(() => room.value?.status),
    jobPost: computed(() => room.value!.jobPost),
    pending,
    token,
    room,
    questions,
    answers,
    answeredQuestions,
    unansweredQuestions,
    currentQuestion,

    getRoom,
    updateRoom,
    submitAnswer,
  }
})
