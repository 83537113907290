export interface RoomPreferencesSettings {
  // sidebars
  isSidebarLeft: boolean
  isSidebarRight: boolean

  // sections
  isSectionFaqs: boolean
  isSectionJobDetails: boolean
  isSectionMediaSettings: boolean

  // app
  isHeader: boolean
  isRoomStarted: boolean
  isOnBoarding: boolean

  // media
  isCamera: boolean
  isMicrophone: boolean
  isMirror: boolean

  // camera
  isVirtualBackground: boolean
  cameraVirtualBackground: string
}

export interface UserPreferencesSettings {
  // TODO: add user preferences types here
}

export interface UserSettings {
  preferences: Partial<UserPreferencesSettings>
}

export interface RoomSettings {
  preferences: Partial<RoomPreferencesSettings>
}

export function getDefaultUserSettings(): UserSettings {
  return {
    preferences: {
      // TODO: add default user preferences here
    },
  }
}

export function getDefaultRoomSettings(): RoomSettings {
  return {
    preferences: {
      isSidebarLeft: false,
      isSidebarRight: false,
      isSectionFaqs: false,
      isSectionJobDetails: false,
      isSectionMediaSettings: false,
      isHeader: true,
      isRoomStarted: false,
      isOnBoarding: false,
      isCamera: true,
      isMicrophone: true,
      isMirror: false,
      isVirtualBackground: false,
      cameraVirtualBackground: 'system-blur',
    },
  }
}
